/* eslint-disable no-console */
/* global window, document */

module.exports = {
  onClientEntry: () => {
    const isDebug = window.location.search.indexOf("debug") > -1;

    if (isDebug) {
      console.log("Running in visual debug mode");

      document.querySelector("body").classList.add("debug");
    }
  },
};
